<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-10 is-offset-1">
        <div>
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(fetchStudentCummulativeTotal)">
              <div class="columns is-multiline">
                <div class="column is-4">
                  <SdSelect
                    rules="required"
                    label="School Class"
                    v-model="schoolClassId"
                    expanded
                  >
                    <option
                      v-for="schoolClass in schoolClasses"
                      :key="schoolClass.id"
                      :value="schoolClass.id"
                    >
                      {{ schoolClass.name }}
                    </option>
                  </SdSelect>
                </div>
                <div class="column is-4">
                  <SdSelect
                    rules="required"
                    label="Subjects"
                    v-model="subjectId"
                    expanded
                  >
                    <option
                      v-for="subject in subjects"
                      :key="subject.subject.id"
                      :value="subject.subject.id"
                    >
                      {{ subject.subject.name }}
                    </option>
                  </SdSelect>
                </div>
                <div class="column is-4">
                  <SdSelect rules="required" label="Term" v-model="termId">
                    <option
                      v-for="term in terms"
                      :key="term.id"
                      :value="term.id"
                    >
                      {{ term.title }}
                    </option>
                  </SdSelect>
                </div>
                <div class="column is-12">
                  <div class="buttons is-right">
                    <button class="button is-success">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="showStudents">
        <template v-if="loading">
          <div class="column is-10 is-offset-1">
            <loading />
          </div>
        </template>
        <template v-else>
          <div class="column is-10 is-offset-1">
            <div>
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <form @submit.prevent="passes(update)">
                  <div class="column is-12">
                    <b-table :data="studentsCummulativeTotal" hoverable>
                      <b-table-column
                        field="#"
                        label="#"
                        width="40"
                        numeric
                        v-slot="props"
                        >{{
                          studentsCummulativeTotal.indexOf(props.row) + 1
                        }}</b-table-column
                      >

                      <b-table-column label="Name" v-slot="props">
                        {{ props.row.student.name }}
                      </b-table-column>

                      <b-table-column label="Total" width="200" v-slot="props">
                        <SdInput
                          type="text"
                          rules="required"
                          :name="`${props.row.student.id}-total`"
                          v-model="props.row.total"
                        />
                      </b-table-column>
                    </b-table>
                  </div>
                  <div class="column is-12">
                    <button
                      type="submit"
                      class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TERMS from '../../../graphql/term/Terms.gql'
import SdSelect from '../../components/SdSelect'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  data() {
    return {
      user: {},
      schoolId: null,
      pageTitle: 'Cummulative Termly Total',
      termId: null,
      terms: [],
      subjects: [],
      subjectId: null,
      schoolClassId: null,
      schoolClasses: [],
      cummulativeScores: [],
      loading: false,
      showStudents: false,
      studentsCummulativeTotal: [],
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  components: {
    SdSelect,
    SdInput,
    ValidationObserver,
  },
  watch: {
    schoolClassId(value) {
      if (value) {
        this.fetchSubjects()
      }
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Ate Assessment',
        route: `/school/${this.schoolId}/ate_assessment`,
      },
      {
        name: 'Cummulative scores',
        route: `/school/${this.schoolId}/cumulative_scores`,
      },
    ])

    fetchUser().then((user) => {
      this.user = user
      this.fetchSchoolClasses()
    })
  },
  methods: {
    fetchSubjects() {
      this.$apollo
        .query({
          query: gql`
            query AssignedSubjectsInSchoolClassQuery(
              $schoolId: ID!
              $schoolClassId: ID!
            ) {
              assignedSubjectsInSchoolClass(
                schoolId: $schoolId
                schoolClassId: $schoolClassId
              ) {
                id
                subject {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            schoolId: parseInt(this.schoolId),
            schoolClassId: parseInt(this.schoolClassId),
          },
        })
        .then((response) => {
          this.subjects = response.data.assignedSubjectsInSchoolClass
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchStudentCummulativeTotal() {
      this.loading = true
      this.showStudents = true

      this.$apollo
        .query({
          query: gql`
            query StudentsCummulativeTotals(
              $subjectId: ID!
              $schoolClassId: ID!
              $termId: ID!
            ) {
              cummulativeTotals(
                subjectId: $subjectId
                schoolClassId: $schoolClassId
                termId: $termId
              ) {
                id
                student {
                  id
                  name
                }
                total
                createdAt
                updatedAt
              }
            }
          `,
          variables: {
            subjectId: parseInt(this.subjectId),
            schoolClassId: parseInt(this.schoolClassId),
            termId: parseInt(this.termId),
          },
        })
        .then((response) => {
          this.studentsCummulativeTotal = response.data.cummulativeTotals
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getData() {
      return this.studentsCummulativeTotal.map((cummulative) => {
        return {
          id: cummulative.id,
          student_id: cummulative.student.id,
          term_id: cummulative.term_id,
          average: cummulative.average,
          total: cummulative.total,
          created_at: cummulative.createdAt,
          updated_at: new Date().toUTCString(),
        }
      })
    },
    update() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateCummulativeResultQuery($data: JSON!) {
              updateCummulativeResult(input: { data: $data }) {
                errors
              }
            }
          `,
          // Parameters
          variables: {
            data: this.getData(),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateCummulativeResult.errors,
            'Successfully updated.'
          ).then(() => {
            // this.$router.push(
            //   `/school/${this.schoolId}/students/${schoolClassId}`
            // )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
